// // components/AdminRoute.js
// import React from 'react';
// import { Navigate, Outlet } from 'react-router-dom';
// import { useAuth } from '../../contexts/AuthContext';

// const AdminRoute = () => {
//   const { user } = useAuth();

//   if (user && user.tipousuario === 1) {
//     return <Outlet />;
//   } else {
//     return <Navigate to="/login" />;
//   }
// };

// export default AdminRoute;

// components/routes/AdminRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const AdminRoute = ({ children }) => {
  const { user } = useAuth();

  console.log('AdminRoute - Usuario:', user);

  if (user && user.tipousuario === '1') {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default AdminRoute;
