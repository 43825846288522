import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { login as loginService } from '../services/AuthService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const formik = useFormik({
    initialValues: {
      mail: '',
      pass: ''
    },
    validationSchema: Yup.object({
      mail: Yup.string().email('Email no valido').required('Dato requerido'),
      pass: Yup.string().required('Requerido')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log('Formulario enviado:', values); // Log para verificar el envío

    

// Verifica el valor y tipo de tipousuario


      try {
        const response = await loginService(values.mail, values.pass);
        // Después de actualizar el contexto de autenticación con los datos del usuario
login(response.data.user);

// Verifica el valor y tipo de tipousuario
console.log('Tipousuario:', response.data.user.tipousuario, typeof response.data.user.tipousuario);

        if (response.data.message === 'exito') {
          alert('Inicio de sesión exitoso');

          // Almacena el token JWT en localStorage
          localStorage.setItem('token', response.data.token);

          // Actualiza el contexto de autenticación con los datos del usuario
          login(response.data.user);

          // Redirige según el tipo de usuario
          if (response.data.user.tipousuario === '1') {
            navigate('/admin'); // Ruta para administradores
          } else {
            navigate('/protected'); // Ruta para usuarios regulares
          }
        } else {
          alert(response.data.error || 'Email o contraseña inválidos');
        }
      } catch (error) {
        console.error('Error al iniciar sesión:', error.response ? error.response.data : error);
        alert(error.response ? error.response.data.error : 'Error al iniciar sesión');
      } finally {
        setSubmitting(false);
      }
    }
  });

  return (
    <div className="login">
      <h2>Login</h2>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label>Email</label>
          <input
            type="email"
            name="mail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mail}
          />
          {formik.touched.mail && formik.errors.mail ? (
            <div>{formik.errors.mail}</div>
          ) : null}
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            name="pass"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.pass}
          />
          {formik.touched.pass && formik.errors.pass ? (
            <div>{formik.errors.pass}</div>
          ) : null}
        </div>
        <button type="submit" disabled={formik.isSubmitting}>
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
