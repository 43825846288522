import React from 'react';
import RegisterForm from '../components/RegisterForm';
import '../pages/styles.css';

const RegisterPage = () => {
  return (
    <div className= "register">
      <h1>Retiro de Utiles Escolares</h1>
      <RegisterForm />
    </div>
  );
};

export default RegisterPage;
