import React from 'react';
import './main-loading.css';

export const MainLoading = ()=>{

    return <div className='main-loading'>
        <div>
            <p className='loader'></p>
        </div>
    </div>
}