import axios from 'axios';

const API_URL = 'https://utiles.stipa.org.ar'; // URL base de tu servidor

const testAPI = () => {
  return axios.get(`${API_URL}/test.php`);
};

const login = (mail, pass) => {
  return axios.post(`${API_URL}/login.php`, { mail, pass }, { withCredentials: true });
};

const getUsers = (token) => {
  return axios.get(`${API_URL}/users.php`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const register = (userData) => {
  return axios.post(`${API_URL}/register.php`, userData);
};

const submitForm = (formData) => {
  return axios.post(`${API_URL}/formulario.php`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Exporta las funciones correctamente
export { login, register, submitForm, testAPI, getUsers };
