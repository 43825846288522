// components/ProtectedPage2.js
import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

const ProtectedPage2 = () => {
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para verificar si está cargando
  const [error, setError] = useState(null); // Estado para manejar errores

  useEffect(() => {
    // Función para obtener la lista de usuarios
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://utiles.stipa.org.ar/users.php', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users); // Actualiza el estado con los usuarios
        setLoading(false); // Finaliza el estado de carga
      } catch (error) {
        console.error('Error al obtener usuarios:', error);
        setError('No se pudo cargar la lista de usuarios'); // Guarda el error en el estado
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Función para eliminar un usuario
  const handleDeleteUser = async (userId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este usuario?')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`https://utiles.stipa.org.ar/users.php?id=${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers((prevUsers) => prevUsers.filter((u) => u.id !== userId)); // Actualiza el estado después de eliminar
        alert('Usuario eliminado');
      } catch (error) {
        console.error('Error al eliminar usuario:', error);
        alert('Error al eliminar usuario');
      }
    }
  };

  // Función para cambiar la contraseña de un usuario
  const handleChangePassword = async (userId) => {
    const newPassword = window.prompt('Ingrese la nueva contraseña:');
    if (newPassword) {
      try {
        const token = localStorage.getItem('token');
        await axios.put(
          `https://utiles.stipa.org.ar/users.php?id=${userId}`,
          { password: newPassword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('Contraseña actualizada');
      } catch (error) {
        console.error('Error al cambiar contraseña:', error);
        alert('Error al cambiar contraseña');
      }
    }
  };

  if (loading) {
    return <p>Cargando...</p>; // Mensaje mientras carga
  }

  if (error) {
    return <p>{error}</p>; // Muestra el error si existe
  }

  return (
    <div>
      <h1>Panel de Administración</h1>
      <h2>Bienvenido, {user && user.nombre}</h2>

      {users.length > 0 ? (
        <table border="1">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>CUIL</th>
              <th>Empresa</th>
              <th>Email</th>
              <th>Tipo de Usuario</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {users.map((u) => (
              <tr key={u.id}>
                <td>{u.id}</td>
                <td>{u.nombre}</td>
                <td>{u.cuil}</td>
                <td>{u.empresa}</td>
                <td>{u.mail}</td>
                <td>{u.tipousuario}</td>
                <td>
                  <button onClick={() => handleDeleteUser(u.id)}>Eliminar</button>
                  <button onClick={() => handleChangePassword(u.id)}>Cambiar Contraseña</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No hay usuarios disponibles para mostrar.</p> // Mensaje si la lista está vacía
      )}
    </div>
  );
};

export default ProtectedPage2;
